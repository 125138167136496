<template>
  <b-collapse id="filtrosCollapse">
    <!-- Input to filter by campaign name -->
    <div class="d-flex align-items-center row">
      <div class="col-3 w-100">
        <label for="nome">Nome</label>
        <b-form-input
          v-model="filtroCampanhaObj.nome"
          placeholder="Filtrar por nome da campanha"
          class="mr-2"
          @input="filtrarCampanhas"
        ></b-form-input>
      </div>

      <div class="col-3 w-100">
        <label for="status">Canais</label>
        <MultiSelect
          v-model="filtroCampanhaObj.canais"
          :options="canais"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por canais"
          class="w-100"
          @input="filtrarCampanhas"
          optionLabel="text"
        ></MultiSelect>
      </div>
      <div class="col-3 w-100">
        <label for="subconta">Contas</label>
        <MultiSelect
          v-model="filtroCampanhaObj.subcontas"
          :options="subcontas"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por subconta"
          class="w-100"
          @input="filtrarCampanhas"
          optionLabel="text"
        ></MultiSelect>
      </div>
      <div class="col-3 w-100">
        <label for="subconta">Tipo de Campanha</label>
        <MultiSelect
          v-model="filtroCampanhaObj.tiposDeCampanha"
          :options="tiposDeCampanha"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por tipo de campanha"
          class="w-100"
          @input="filtrarCampanhas"
          optionLabel="text"
        ></MultiSelect>
      </div>
      <div class="col-12 w-100 mt-2">
        <label for="subconta">Modelos</label>
        <MultiSelect
          v-model="filtroCampanhaObj.modelos"
          :options="modelos"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por modelo"
          class="w-100"
          @input="filtrarCampanhas"
          optionLabel="text"
        ></MultiSelect>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import MultiSelect from "primevue/multiselect";
import { mapGetters } from "vuex";

export default {
  name: "FiltrosCampanha",
  components: {
    MultiSelect
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "layoutConfig"])
  },
  data() {
    return {
      filtroCampanhaObj: {
        nome: "",
        canais: [],
        subcontas: [],
        modelos: [],
        tiposDeCampanha: [],
        contaPrincipal: {}
      },
      campanhas: [],
      canais: [],
      subcontas: [],
      modelos: [],
      tiposDeCampanha: [],
      filtrosIniciais: {}
    };
  },
  methods: {
    pegarDadosCache() {
      const filtrosSalvos = localStorage.getItem(
        "filtrosCampanha-" + this.currentUserPersonalInfo.login
      );
      if (filtrosSalvos) {
        this.filtroCampanhaObj = JSON.parse(filtrosSalvos);
        this.filtrarCampanhas();
      }
    },
    setarFiltroDeReset() {
      this.filtrosIniciais = { ...this.filtroCampanhaObj };
    },
    limparFiltros() {
      this.filtroCampanhaObj = { ...this.filtrosIniciais };
      this.filtrarCampanhas();
    },
    salvarEmLocalStorage() {
      localStorage.setItem(
        "filtrosCampanha-" + this.currentUserPersonalInfo.login,
        JSON.stringify(this.filtroCampanhaObj)
      );
    },
    filtrarCampanhas() {
      this.salvarEmLocalStorage();
      this.$emit("filtrarCampanhas", this.filtroCampanhaObj);
    },
    async pegarSubcontas() {
      const response = await ApiService.get(API_LINKS.contasAtivas, "", false);

      const visited_rules = {};

      this.subcontas = response.data.subContas.map((subconta) => {
        const nome_conta = subconta.nome_conta_cliente;
        if (visited_rules[subconta.nome_conta_cliente] > 0) {
          subconta.nome_conta_cliente += " (" + visited_rules[nome_conta] + ")";
        }
        visited_rules[nome_conta] = (visited_rules[nome_conta] || 0) + 1;
        return {
          ...subconta,
          text:
            (subconta.con_nome_identificacao ?? subconta.con_login) +
            " (" +
            subconta.conta_id +
            ")",
          code: subconta.conta_id
        };
      });

      const conta = response.data.contaPrincipal;

      const contaPrincipal = {
        ...conta,
        text: "Conta Principal (" + conta?.conta_id + ")",
        code: conta?.conta_id
      };

      this.filtroCampanhaObj.contaPrincipal = contaPrincipal;
      this.subcontas.push(contaPrincipal);
      // this.filtroCampanhaObj.subcontas = this.subcontas;
      return response;
    },
    async pegarCampanhas() {
      const res = await ApiService.get(API_LINKS.campanha);
      this.campanhas = res.data.campanhas;
      return res;
    },
    async pegaeTipsoCampanha() {
      const response = await ApiService.get(API_LINKS.tipoCampanha, "", false);
      this.tiposDeCampanha = response.data.tiposCampanha.map(
        (campanha_tipo) => {
          return {
            ...campanha_tipo,
            code: campanha_tipo.tipo_campanha_id,
            text: campanha_tipo.tcm_nome
          };
        }
      );
      this.tiposDeCampanha.unshift({
        code: null,
        text: "Sem tipo"
      });
      return response;
    },

    async pegarCanais() {
      const response = await ApiService.get(
        API_LINKS.campanha + "/canal-envio",
        "",
        false
      );

      const canaisDasCampanhas = [];
      this.campanhas.forEach((campanha) => {
        campanha.CANAIS.forEach((canal) => {
          if (canaisDasCampanhas.indexOf(canal.canal_id) === -1)
            canaisDasCampanhas.push(canal.canal_id);
        });
      });

      response.data.canais.forEach((canal) => {
        if (canaisDasCampanhas.indexOf(canal.canal_id) === -1) return;
        this.canais.push({
          ...canal,
          text: canal.can_nome,
          code: canal.canal_id
        });
      });
      // .filter((canal) => canaisDeCampanha.includes(canal.canal_id));
      // this.filtroCampanhaObj.canais = this.canais.sort((a, b) => {
      //   return a.can_nome > b.can_nome ? 1 : -1;
      // });
      return response;
    },
    async pegarModelos() {
      const response = await ApiService.get(
        API_LINKS.campanha + "/modelo",
        "",
        false
      );

      const modelosDasCampanhas = [];

      this.campanhas.forEach((campanha) => {
        if (modelosDasCampanhas.indexOf(campanha.modelo_id) === -1)
          modelosDasCampanhas.push(campanha.modelo_id);
      });

      const modelsAlreadyInserted = [];
      response.data.modelos.forEach((modelo, idx, arr) => {
        if (modelosDasCampanhas.indexOf(modelo.modelo_id) === -1) return;
        modelsAlreadyInserted.push(modelo.modelo_id);
        if (this.modelos.find((m) => m.md_descricao == modelo.md_descricao)) {
          modelo.md_descricao += " " + (Math.random() * 100).toFixed(0);
        }
        this.modelos.push({
          ...modelo,
          text: modelo.md_descricao,
          code: modelo.modelo_id
        });
      });

      // this.filtroCampanhaObj.modelos = this.modelos;
    }
  },
  mounted() {
    const init = async () => {
      await this.pegarCampanhas();
      await this.pegarCanais();
      await this.pegarModelos();
      await this.pegarSubcontas();
      await this.pegaeTipsoCampanha();
      await this.setarFiltroDeReset();
      this.pegarDadosCache();

      const tipo = this.$route.query.campanhaTipo;
      if (tipo) {
        this.filtroCampanhaObj.tiposDeCampanha = this.tiposDeCampanha.filter(
          (t) => t.tipo_campanha_id == tipo
        );
        this.filtroCampanhaObj.tiposDeCampanha.push({
          code: null,
          text: "Sem tipo"
        });

        this.filtroCampanhaObj.canais = JSON.parse(JSON.stringify(this.canais));
        this.filtroCampanhaObj.modelos = JSON.parse(
          JSON.stringify(this.modelos)
        );
        this.filtroCampanhaObj.subcontas = JSON.parse(
          JSON.stringify(this.subcontas)
        );
      }
    };
    init();
  }
};
</script>

<style lang="scss" scoped>
#filtrosCollapse {
  padding: 0.5rem 1.5rem;
}
</style>
